<template>
  <div>
    <h1 class="title">Inloggen</h1>
    <div class="field">
      <label class="label">Emailadres</label>
      <div class="control">
        <input
          class="input"
          v-bind:class="{ 'is-danger': error }"
          v-model="email"
          v-on:keyup.enter="login"
          placeholder="voorbeeld@email.nl"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Wachtwoord</label>
      <div class="control">
        <input
          class="input"
          v-bind:class="{ 'is-danger': error }"
          type="password"
          v-model="password"
          v-on:keyup.enter="login"
          placeholder="*************"
        />
      </div>
      <p v-if="error" class="help is-danger">{{ error }}</p>
    </div>
    <div class="field">
      <div class="control">
        <button
          v-on:click="login"
          :disabled="loggingIn"
          :class="loggingIn ? 'button is-loading' : 'button'"
        >
          Inloggen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      error: null,
      success: false,
      email: null,
      password: null,
      loggingIn: false
    }
  },
  methods: {
    showError(error) {
      this.error = error
      this.loggingIn = false
      setTimeout(() => {
        this.error = null
      }, 3000)
    },
    login() {
      this.success = false
      this.error = null
      this.loggingIn = true

      if (!this.email) return this.showError('Vul een emailadres in')
      if (!this.password) return this.showError('Vul een wachtwoord in')
      // Login
      this.$store
        .dispatch('authenticate', {
          email: this.email,
          password: this.password
        })
        .then(response => {
          this.loggingIn = false
          this.password = null
          if (response.token) return this.$router.push('/')
          if (response.error) {
            this.error = response.error
          } else {
            this.error = 'Oeps, er gaat iets verschrikkelijk mis!'
          }
        })
        .catch(error => {
          this.loggingIn = false
          this.password = null
          this.error = error.message
        })
    }
  }
}
</script>

<style scoped>
.field {
  max-width: 400px;
}
</style>
